/**
 * Maps from file extension to semantic UI icon
 */
 const ext2icon = {
    // image
    jpg:  'image',
    jpeg: 'image',
    png:  'image',
    tiff: 'image',
    gif:  'image',
    svg:  'image',

    // audio
    mp3: 'audio',
    wav: 'audio',
    ogg: 'audio',

    // video
    mp4: 'video',
    avi: 'video',

    // archive
    zip:  'archive',
    rar:  'archive',

    // code
    js:   'code',
    html: 'code',
    css:  'code',

    // pdf
    pdf: 'pdf',

    // excel
    xls:  'excel',
    xlw:  'excel',
    xlt:  'excel',
    csv:  'excel',
    xlsx: 'excel',

    // word
    doc:  'word',
    docx: 'word',
    
    // powerpoint
    ppt:  'powerpoint',
    pptx: 'powerpoint',
    pptm: 'powerpoint',
    
    // alternate (text)
    txt: 'alternate',
    wps: 'alternate',
    md:  'alternate',
};

export const getIconFromExtension = (extension, outline = false) => {
    let lookup = ext2icon[extension];
    let icon = 'file';
    
    if (lookup) {
        icon += ` ${lookup}`;
    }
    
    if (outline) {
        icon += ' outline';
    }

    return icon;
};

/**
 * @param {(reader: FileReader) => void} startHandler 
 */
const readFile = startHandler => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(reader.error);
        startHandler(reader);
    });
};

/**
 * @returns {Promise<string>}
 */
export function readAsText (file) {
    return readFile(reader => reader.readAsText(file, 'UTF-8'));
}

export function readAsBase64 (file) {
    return readFile(reader => reader.readAsDataURL(file));
}

export function base64ToBlob (b64, contentType = 'application/octet-stream') {
    const decoded = window.atob(b64);
    const bytes = new Uint8Array(decoded.length);
    for (let i = 0; i < decoded.length; i++) {
        bytes[i] = decoded.charCodeAt(i);
    }
    return new Blob([ bytes ], { type: contentType });
}

export function getFileName (file) {
    const lastdot = file.name.lastIndexOf('.');
    if (lastdot === -1) {
        return file.name;
    }
    return file.name.substring(0, lastdot);
}

export function getFileExtension(file) {
    const fname = file?.name;
    if (!fname) {
        return '';
    }

    const lidxdot = fname.lastIndexOf('.');
    if (lidxdot === -1) {
        return '';
    }

    return fname.substr(lidxdot + 1).toLowerCase();
}

export function getFileIcon(file, outline = false) {
    const ext = getFileExtension(file);
    const icon = getIconFromExtension(ext, outline);

    return icon;
}

export function formatFileSize({ size }) {
    if (size < 1000) {
        return `${size} B`;
    }

    if (size < 1000000) {
        return `${(size / 1000).toFixed(2)} KB`;
    }

    return `${(size / 1000000).toFixed(2)} MB`;
}

/**
 * @param {string} fileName 
 * @param {string} contentType 
 * @param {string} textData 
 */
export function downloadTextData(fileName, contentType, textData) {
    const blob = new Blob([textData], { type: contentType });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(link.href);
}

export function sanitizeFileName(str) {
    return str
        .replace(/[<>:"\/\\|?*]/g, '_')
        .replace(/\s+/g, '_')
        .replace(/_+/g, '_')
        .replace(/^\.+/, '')
        .trim();
}