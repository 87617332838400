import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Dropdown, Form, Icon, Input, Label, Loader, Message, Tab, Table } from 'semantic-ui-react';
import { capitalize } from 'lodash';
import payment from 'http/payment';
import deepCopy from 'shared/deepCopy';
import { downloadArrayAsCSV } from 'shared/CSV';
import { sanitizeFileName } from 'shared/files';
import readableID from 'shared/readableID';
import useRestResource from 'shared/hooks/useRestResource';
import DatePicker from 'atoms/DatePicker';
import ColoredText from 'atoms/ColoredText';
import UserFilterPipeline, { allFilterChoices, filterChoiceTypes, validateFilterItems } from 'molecules/UserFilterPipeline';

const activeFilterItems = allFilterChoices.filter(item => item.value === filterChoiceTypes.boughtTaxYears);

const Analytics = ({ discountCampaignID, campaignInternalName }) => {
    const { loading, error, data: paymentLogs } = useRestResource({
        fetcher: () => payment.getPaymentLogs({ discountCampaign: discountCampaignID }),
        args: [discountCampaignID],
    });

    const logsByPurchaseIntentID = {};
    for (const logEntry of paymentLogs || []) {
        logsByPurchaseIntentID[logEntry.paymentIntentID] ||= {
            displayName: logEntry.displayName,
            selectedPackageTierName: logEntry.selectedPackageTierName,
            productName: logEntry.productName,
            timestamp: logEntry.timestamp,
            taxYears: [],
            price: 0,
        };

        logsByPurchaseIntentID[logEntry.paymentIntentID].price += logEntry.price;
        logsByPurchaseIntentID[logEntry.paymentIntentID].taxYears.push(logEntry.taxYear);
    }
    const payments = Object.values(logsByPurchaseIntentID);

    const colSpan = 5;
    const numberFormatter = new Intl.NumberFormat();

    const renderRows = () => {
        if (loading) {
            return (
                <Table.Row>
                    <Table.Cell colSpan={colSpan}>
                        <Loader inline='centered' active />
                    </Table.Cell>
                </Table.Row>
            );
        }

        if (error) {
            return (
                <Table.Row>
                    <Table.Cell colSpan={colSpan}>
                        <Message error content='Der opstod en fejl' />
                    </Table.Cell>
                </Table.Row>
            );
        }

        if (payments.length === 0) {
            return (
                <Table.Row>
                    <Table.Cell colSpan={colSpan} textAlign='center'>
                        <i>Der er ikke foretaget nogen køb endnu...</i>
                    </Table.Cell>
                </Table.Row>
            );
        }

        return payments.map(({ displayName, selectedPackageTierName, productName, taxYears, timestamp, price }) => {
            return (
                <Table.Row>
                    <Table.Cell>{displayName}</Table.Cell>
                    <Table.Cell>{selectedPackageTierName || productName}</Table.Cell>
                    <Table.Cell>{taxYears.toSorted().join(', ')}</Table.Cell>
                    <Table.Cell>{new Intl.DateTimeFormat().format(new Date(timestamp))}</Table.Cell>
                    <Table.Cell textAlign='right'>{numberFormatter.format(price)} kr.</Table.Cell>
                </Table.Row>
            )
        });
    };

    const totalEarned = payments.reduce((acc, cur) => acc += cur.price, 0);

    return (
        <Table>
            <Table.Header>
                <Table.HeaderCell>Navn</Table.HeaderCell>
                <Table.HeaderCell>Produkt</Table.HeaderCell>
                <Table.HeaderCell>Skatteår</Table.HeaderCell>
                <Table.HeaderCell>Købstidspunkt</Table.HeaderCell>
                <Table.HeaderCell textAlign='right'>Beløb betalt</Table.HeaderCell>
            </Table.Header>
            <Table.Body>{renderRows()}</Table.Body>
            {!loading && !error && (
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan={colSpan}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ flex: 1 }}>
                                    <ColoredText
                                        link
                                        icon='download'
                                        iconPosition='left'
                                        content='Download CSV'
                                        onClick={() => downloadArrayAsCSV(payments, sanitizeFileName(campaignInternalName))}
                                    />
                                </div>
                                <div>
                                    <Label
                                        icon='tag'
                                        content={`${payments.length} køb i alt`}
                                    />
                                    <Label
                                        icon='money'
                                        content={`${numberFormatter.format(totalEarned)} kr. i alt`}
                                    />
                                </div>
                            </div>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            )}
        </Table>
    );
};

const DiscountCampaignDetail = ({ campaign, onChange, onSave, onCancel }) => {
    const [working, setWorking] = useState(false);

    const productsResource = useRestResource({
        fetcher: payment.getSAASProducts,
        args: [],
    })

    const validateCampaign = () => {
        if (!campaign.name) {
            return 'Indtast et kampagnenavn';
        }     

        if (!campaign.startDate) {
            return 'Vælg en startdato';
        }           

        if (!campaign.endDate) {
            return 'Vælg en slutdato';
        }                   

        if (new Date(campaign.startDate) >= new Date(campaign.endDate)) {
            return 'Kampagnes startdato skal være mindre end slutdatoen';
        }

        if (!campaign.discountType) {
            return 'Vælg en rabattype';
        }

        const amountOff = campaign.amountOff || 0;

        if (amountOff <= 0) {
            return 'Rabatbeløb skal være større end 0';
        }

        if (campaign.discountType === 'PERCENT' && amountOff > 100) {
            return 'Procent må ikke være over 100';
        }

        if (!campaign.saasProducts?.length) {
            return 'Vælg mindst ét produkt';
        }

        const { valid, validationErrors } = validateFilterItems(campaign.userFilterItems || [], activeFilterItems);
        if (!valid) {
            const formattedErrors = validationErrors.map(error => `filter #${error.index + 1}: ${error.message}`);
            return capitalize(formattedErrors.join(', '));
        }

        return null; // campaign is valid
    };

    const onSaveClicked = async () => {
        const validationError = validateCampaign();
        if (validationError) {
            toast.error(validationError);
            return;
        }

        try {
            setWorking(true);
            await onSave();
        } catch {
            setWorking(false);
        }
    };

    const formFields = (
        <Form>
            <Form.Field>
                <label>Internt navn (kan kun ses af admins)</label>
                <Input
                    placeholder='Internt kampagnenavn...'
                    defaultValue={campaign.internalName}
                    onChange={(_, { value }) => onChange({ ...campaign, internalName: value })}
                />
            </Form.Field>
            <Form.Field disabled={working}>
                <label>Navn (vises til brugeren i indkøbskurven)</label>
                <Input
                    placeholder='Kampagnenavn...'
                    defaultValue={campaign.name}
                    onChange={(_, { value }) => onChange({ ...campaign, name: value })}
                />
            </Form.Field>
            <Form.Group widths='equal'>
                <Form.Field disabled={working}>
                    <label>Startdato</label>
                    <DatePicker
                        placeholder='Vælg kampagnens startdato...'
                        value={campaign.startDate}
                        onChange={startDate => onChange({ ...campaign, startDate: new Date(startDate) })}
                    />
                </Form.Field>
                <Form.Field disabled={working}>
                    <label>Udløbsdato (kampagnen er ikke aktiv på selve datoen)</label>
                    <DatePicker
                        placeholder='Vælg kampagnens slutdato...'
                        value={campaign.endDate}
                        onChange={endDate => onChange({ ...campaign, endDate: new Date(endDate) })}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Field disabled={working}>
                    <label>Vælg rabattype</label>
                    <Dropdown
                        selection
                        defaultValue={campaign.discountType}
                        placeholder='Vælg rabattype...'
                        onChange={(_, { value }) => onChange({ ...campaign, discountType: value })}
                        options={[
                            { text: 'Procent', value: 'PERCENT' },
                            { text: 'Fast beløb', value: 'FLAT', disabled: true, description: 'Ikke implementeret endnu...' },
                        ]}
                    />
                </Form.Field>
                <Form.Field disabled={working}>
                    <label>Beløb</label>
                    <Input
                        type='number'
                        placeholder='Indtast beløb...'
                        defaultValue={campaign.amountOff}
                        onChange={(_, { value }) => onChange({ ...campaign, amountOff: Number(value) })}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Field disabled={working}>
                <label>Vælg produkter</label>
                <Dropdown
                    selection
                    multiple
                    placeholder='Vælg hvilke produkter der skal være rabat på...'
                    defaultValue={campaign.saasProducts}
                    onChange={(_, { value }) => onChange({ ...campaign, saasProducts: value })}
                    loading={productsResource.loading}
                    error={productsResource.error}
                    options={(productsResource.data || []).map(product => {
                        return {
                            text: product.descriptiveName,
                            value: product.id,
                        };
                    })}
                />
            </Form.Field>
            <Form.Field>
                <label>Yderligere målgruppefiltrering</label>
                <UserFilterPipeline
                    filterItems={campaign.userFilterItems || []}
                    setFilterItems={items => onChange({ ...campaign, userFilterItems: items })}
                    activeFilterChoices={activeFilterItems}
                />
            </Form.Field>
        </Form>
    );

    const navigation = (
        <div style={{ textAlign: 'right', marginTop: '1em' }}>
            <Button
                content='Tilbage'
                color='black'
                onClick={() => onCancel()}
                disabled={working}
            />
            <Button
                primary
                content='Gem'
                icon='save'
                loading={working}
                disabled={working}
                onClick={onSaveClicked}
            />
        </div>
    );

    return (
        <Tab
            panes={[
                {
                    menuItem: 'Kampagnedetaljer',
                    render: () => (
                        <Tab.Pane>
                            {formFields}
                            {navigation}
                        </Tab.Pane>
                    ),
                },
                {
                    menuItem: 'Analytics',
                    render: () => (
                        <Tab.Pane>
                            {
                                '_id' in campaign
                                    ? <Analytics discountCampaignID={campaign._id} campaignInternalName={campaign.internalName} />
                                    : <div>Se købsoversigt her når kampagnen er i gang</div>
                            }
                            {navigation}
                        </Tab.Pane>
                    ),
                },
            ]}
        />
    );
}

const DiscountCampaigns = () => {
    const [campaignBeingEdited, setCampaignBeingEdited] = useState(null);
    const [sortBy, setSortBy] = useState('creationDate');
    const [sortAsc, setSortAsc] = useState(false);

    const { loading, error, data: campaigns, refetch: refetchCampaigns } = useRestResource({
        fetcher: payment.getAllDiscountCampaigns,
        args: [],
    });

    const handleSaveCampaign = async () => {
        const shouldUpdate = '_id' in campaignBeingEdited;

        if (shouldUpdate) {
            await payment.updateDiscountCampaign(campaignBeingEdited._id, campaignBeingEdited);
            toast.success('Kampagnen blev opdateret');
        } else {
            await payment.createDiscountCampaign(campaignBeingEdited);
            toast.success('Kampagnen blev oprettet');
        }

        refetchCampaigns();
        setCampaignBeingEdited(null);
    };

    if (campaignBeingEdited) {
        return (
            <DiscountCampaignDetail
                campaign={campaignBeingEdited}
                onChange={newCampaignData => setCampaignBeingEdited(newCampaignData)}
                onSave={handleSaveCampaign}
                onCancel={() => setCampaignBeingEdited(null)}
            />
        );
    }

    const sortedCampaigns = (campaigns || []).sort((campaignA, campaignB) => {
        const valA = campaignA[sortBy];
        const valB = campaignB[sortBy];

        if (valA > valB) return sortAsc ? 1 : -1;
        if (valA < valB) return sortAsc ? -1 : 1;
        return 0;
    });

    const getCellSortProps = cellSortBy => {
        let sortDirection;
        if (cellSortBy === sortBy) {
            sortDirection = sortAsc ? 'ascending' : 'descending';
        }

        return {
            sorted: sortDirection,
            onClick: () => {
                if (sortBy === cellSortBy) {
                    setSortAsc(!sortAsc);
                    return;
                }
        
                setSortAsc(false);
                setSortBy(cellSortBy);
            },
        };
    };

    const amountOfColumns = 7;

    return (
        <Table sortable celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell {...getCellSortProps('name')} content='Navn' />
                    <Table.HeaderCell {...getCellSortProps('creationDate')} content='Oprettet' />
                    <Table.HeaderCell {...getCellSortProps('startDate')} content='Aktiv fra' />
                    <Table.HeaderCell {...getCellSortProps('endDate')} content='Udløber' />
                    <Table.HeaderCell style={{ pointerEvents: 'none' }}>Status</Table.HeaderCell>
                    <Table.HeaderCell style={{ pointerEvents: 'none' }}>Produkter</Table.HeaderCell>
                    <Table.HeaderCell style={{ pointerEvents: 'none' }} textAlign='right'>Rabat</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {loading && (
                    <Table.Row>
                        <Table.Cell colSpan={amountOfColumns}>
                            <Loader inline='centered' active />
                        </Table.Cell>
                    </Table.Row>
                )}

                {error && (
                    <Table.Row>
                        <Table.Cell colSpan={amountOfColumns}>
                            <Message error content='Der opstod en fejl' />
                        </Table.Cell>
                    </Table.Row>
                )}

                {sortedCampaigns.map(campaign => {
                    const { _id, internalName, creationDate, startDate, endDate, saasProducts, discountType, amountOff } = campaign;

                    const discountText = (
                        discountType === 'FLAT'
                            ? `${amountOff} kr.`
                            : `${amountOff} %`
                    );

                    const dateFormatter = new Intl.DateTimeFormat();

                    const start = new Date(startDate);
                    const end = new Date(endDate);
                    const now = new Date();

                    let statusText;
                    let statusIcon;
                    let statusColor;

                    if (now < start) {
                        statusText = 'Afventer';
                        statusIcon = 'clock';
                        statusColor = 'yellow';
                    } else if (now >= end) {
                        statusText = 'Færdig';
                        statusIcon = 'check circle';
                        statusColor = 'green';
                    } else {
                        statusText = 'I gang';
                        statusIcon = 'cog';
                        statusColor = 'black';
                    }

                    return (
                        <Table.Row
                            key={_id}
                            onClick={() => setCampaignBeingEdited(deepCopy(campaign))}
                            style={{ cursor: 'pointer' }}
                        >
                            <Table.Cell>
                                <ColoredText
                                    link
                                    color='blue'
                                    content={internalName}
                                    underlined={false}
                                />
                            </Table.Cell>
                            <Table.Cell>{dateFormatter.format(new Date(creationDate))}</Table.Cell>
                            <Table.Cell>{dateFormatter.format(new Date(startDate))}</Table.Cell>
                            <Table.Cell>{dateFormatter.format(new Date(endDate))}</Table.Cell>
                            <Table.Cell>
                                <Icon name={statusIcon} color={statusColor} /> {statusText}
                            </Table.Cell>
                            <Table.Cell>{saasProducts.map(readableID).join(', ')}</Table.Cell>
                            <Table.Cell textAlign='right'>{discountText}</Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
            <Table.Footer fullWidth>
                <Table.Row>
                    <Table.HeaderCell colSpan={amountOfColumns} textAlign='right'>
                        <Button
                            primary
                            content='Opret kampagne'
                            onClick={() => setCampaignBeingEdited({})}
                        />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );
};

export default DiscountCampaigns;