import { useEffect, useState } from 'react';
import { Dropdown, Icon, Header, Table, Pagination, Popup, Button, Loader } from 'semantic-ui-react';
import { injectPaymentInformation } from 'shared/payment';
import { roleMap } from 'shared/roles';
import accounts from 'http/accounts';
import UserFilterPipeline, { allFilterChoices, filterChoiceTypes, validateFilterItems } from 'molecules/UserFilterPipeline';

const segments = {
    Boligudlejere:     'private',
    Rådgivere:         'accountant',
    Virksomheder:      'classA',
    Selskaber:         'classB',
    Holdingselskaber:  'holdingCompanies',
    Interessentskaber: 'interessentskaber',
};

const UsersOverview = ({ uids }) => {
    const [open, setOpen] = useState(false);
    const [fullUsers, setFullUsers] = useState(null);

    useEffect(() => {
        if (!open) return;
        if (fullUsers) return;
        if (!uids) return;
        if (uids.length === 0) return;

        const fetchUsers = async () => {
            const fullUsers = await Promise.all(uids.map(uid => {
                return accounts.getAccountByID(uid);
            }));

            await injectPaymentInformation(fullUsers);

            setFullUsers(fullUsers);
        };

        fetchUsers();
    }, [uids, open, fullUsers]);

    const renderTrigger = () => {
        return (
            <span style={{ cursor: 'pointer' }}>
                <Icon name={uids.length > 1 ? 'users' : 'user'} />{' '}
                {uids.length} bruger{uids.length !== 1 && 'e'}
            </span>
        );
    };

    const renderContent = () => {
        if (!fullUsers) {
            return <Loader inline='centered' active />;
        }

        return (
            <Table basic='very' style={{ width: '500px' }}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Navn</Table.HeaderCell>
                        <Table.HeaderCell>Roller</Table.HeaderCell>
                        <Table.HeaderCell textAlign='right'>Skatteår købt</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {fullUsers.map(user => {
                        const allOwnedTaxYears = new Set();
                        for (const taxYears of Object.values(user.ownedProducts || {})) {
                            taxYears.forEach(taxYear => allOwnedTaxYears.add(taxYear));
                        }
                        const sortedTaxYears = [...allOwnedTaxYears].sort();

                        const rolesToShow = user.roles.filter(role => {
                            if (role === roleMap.privat.id) return false;
                            if (role === roleMap.erhverv.id) return false;
                            if (!(role in roleMap)) return false;
                            return true;
                        });

                        const formattedRoles = [user.getTitle(), ...rolesToShow.map(role => roleMap[role].name)];

                        return (
                            <Table.Row>
                                <Table.Cell><Icon name={user.getIcon()} /> {user.displayName}</Table.Cell>
                                <Table.Cell>{formattedRoles.join(', ')}</Table.Cell>
                                <Table.Cell textAlign='right'>{sortedTaxYears.join(', ') || <>-</>}</Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        );
    };

    return (
        <Popup
            position='top right'
            on='click'
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            trigger={renderTrigger()}
            content={renderContent()}
        />
    );
};

const AudiencePreview = ({ relevantSegments, validFilterItems }) => {
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);
    const [page, setPage] = useState(0);
    const pageSize = 5;

    const canSearch = relevantSegments.length > 0;

    const doSearch = async () => {
        if (!canSearch) return;

        setLoading(true);

        const searchResult = await accounts.searchNewletterRecipients({
            relevantSegments,
            filterItems: validFilterItems,
        });

        setPage(0);
        setResult(searchResult);
        setLoading(false);
    };

    const colSpan = '3';
    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.HeaderCell>Brugere</Table.HeaderCell>
                    <Table.HeaderCell textAlign='right'>Sidst aktiv</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {result?.slice(pageSize * page, (pageSize * page) + pageSize)?.map(({ loginEmail, latestLogin, uids }) => {
                    return (
                        <Table.Row key={loginEmail}>
                            <Table.Cell>
                                <div style={{ width: '180px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                    {loginEmail}
                                </div>
                            </Table.Cell>
                            <Table.Cell style={{ width: '120px' }}>
                                <UsersOverview uids={uids} />
                            </Table.Cell>
                            <Table.Cell textAlign='right'>{new Date(latestLogin).toLocaleDateString()}</Table.Cell>
                        </Table.Row>
                    );
                })}
                {!result && !loading && (
                    <Table.Row>
                        <Table.Cell colSpan={colSpan} textAlign='center'>
                            <i>Ingen søgning foretaget...</i>
                        </Table.Cell>
                    </Table.Row>
                )}
                {!result && loading && (
                    <Table.Row>
                        <Table.Cell colSpan={colSpan} textAlign='center'>
                            Søger...
                        </Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
            <Table.Footer>
                {result?.length > 0 && (
                    <Table.Row>
                        <Table.HeaderCell colSpan={colSpan} textAlign='center'>
                            <Pagination
                                activePage={page + 1}
                                totalPages={Math.ceil(result.length / pageSize)}
                                siblingRange={0}
                                onPageChange={(_, { activePage }) => setPage(activePage - 1)}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                )}
                <Table.Row>
                    <Table.HeaderCell colSpan={colSpan} verticalAlign='middle'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 1 }}>
                                {result && (
                                    <span>
                                        <Icon name='users' />
                                        Antal modtagere fundet i alt:{' '}
                                        {
                                            loading
                                                ? <Loader size='tiny' inline active />
                                                : <b>{new Intl.NumberFormat().format(result.length)}</b>
                                        }
                                    </span>
                                )}
                            </div>
                            <div>
                                <Button
                                    basic
                                    color='green'
                                    loading={loading}
                                    floated='right'
                                    content='Søg'
                                    icon='search'
                                    disabled={!canSearch || loading}
                                    onClick={doSearch}
                                />
                            </div>
                        </div>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );
};

const RecipientSelector = ({ recipientSearchConfiguration, onChange }) => {
    const filterItems = recipientSearchConfiguration?.filterItems || [];
    const relevantSegments = recipientSearchConfiguration?.relevantSegments || [];

    const update = (updator = {}) => {
        onChange({
            filterItems,
            relevantSegments,
            ...updator,
        });
    };

    const setFilterItems = filterItems => update({ filterItems });
    const setRelevantSegments = relevantSegments => update({ relevantSegments });

    const getActiveFilterChoices = () => {
        return allFilterChoices.filter(choice => {
            if (choice.value === filterChoiceTypes.boughtTaxYears) {
                return !relevantSegments.includes(segments.Rådgivere);
            }

            return true;
        });
    };

    const getValidFilterItems = () => {
        const validationResult = validateFilterItems(filterItems, getActiveFilterChoices());
        return filterItems.filter((_, idx) => {
            return !validationResult.validationErrors.some(error => error.index === idx);
        });
    };

    const renderFilterItems = () => {
        const filterItems = recipientSearchConfiguration?.filterItems || [];

        return (
            <UserFilterPipeline
                filterItems={filterItems}
                setFilterItems={setFilterItems}
                activeFilterChoices={getActiveFilterChoices()}
            />
        );
    };

    return (
        <div style={{ display: 'flex', gap: '2em', marginTop: '2em' }}>
            <div style={{ flex: 1 }}>
                <Header>Vælg modtagergruppe(r)</Header>
                <Dropdown
                    selection
                    multiple
                    fluid
                    placeholder='Vælg modtagergruppe(r)'
                    defaultValue={recipientSearchConfiguration?.relevantSegments}
                    onChange={(_, { value }) => setRelevantSegments([...value])}
                    options={Object.entries(segments).map(([text, value]) => {
                        return { text, value };
                    })}
                />
                
                <Header>Yderligere filtrering</Header>
                {renderFilterItems()}
            </div>
            <div style={{ width: '450px' }}>
                <Header>Søgeresultat</Header>
                <AudiencePreview
                    relevantSegments={relevantSegments}
                    validFilterItems={getValidFilterItems()}
                />
            </div>
        </div>
    );
};

export default RecipientSelector;
