import { useRef, useState } from 'react';
import { Dropdown, Icon, Segment, Popup, List, Divider } from 'semantic-ui-react';
import uuid from 'uuid/v4';
import ColoredText from 'atoms/ColoredText';
import getRelevantTaxYears from 'shared/getRelevantTaxYears';

const Dot = () => <span>&nbsp;∙</span>;

const FilterItemDropdown = ({ defaultValue, options, onChange, placeholder, disabled, ...otherProps }) => {
    const selectedOption = options.find(option => option.value === defaultValue);
    const textToDisplay = selectedOption?.text || placeholder || '...';

    const trigger = (
        <ColoredText
            link
            lineThrough={disabled}
            bold={!!selectedOption}
            italic={!selectedOption}
            underlined={false}
            content={textToDisplay}
            color={selectedOption ? 'black' : 'grey'}
        />
    );

    return (
        <Dropdown
            defaultOpen
            icon={null}
            defaultValue={defaultValue}
            selectOnBlur={false}
            trigger={trigger}
            onChange={(_, { value }) => onChange(value)}
            options={options}
            disabled={disabled}
            {...otherProps}
        />
    );
};

const TaxYearPicker = ({ state, setState }) => {
    const update = (updator = {}) => {
        const cpy = { ...state };
        cpy.filterOptions = { ...cpy.filterOptions, ...updator };
        setState(cpy);
    };

    const options = state.filterOptions || {};
    const taxYears = options.taxYears || [];

    const components = [];
    const taxYearOptions = getRelevantTaxYears().filter(x => !taxYears.includes(x));

    for (let i = 0; i < taxYears.length + 1; i++) {
        const currentSelectedTaxYear = taxYears[i];

        const isLast = i === taxYears.length - 1;
        if ((taxYears.length === 1 || isLast) && i > 0) {
            components.push(
                <FilterItemDropdown
                    placeholder='(og/eller)'
                    defaultOpen={false}
                    key={`${i}:op:${options.operator}`}
                    options={[
                        { text: 'og', value: 'and' },
                        { text: 'eller', value: 'or' },
                    ]}
                    defaultValue={options.operator}
                    onChange={operator => update({ operator })}
                />
            );

            if (!options.operator) break;
        }

        const taxYearsToShow = [...taxYearOptions];
        if (currentSelectedTaxYear) taxYearsToShow.push(currentSelectedTaxYear);

        const renderedOptions = taxYearsToShow.sort().map(year => ({ text: year, value: year }));
        if (currentSelectedTaxYear) renderedOptions.push({ text: <span style={{ color: 'red' }}>Slet</span>, value: 'slet' });

        components.push(
            <FilterItemDropdown
                defaultOpen={false}
                key={`${i}:${currentSelectedTaxYear}`}
                options={renderedOptions}
                defaultValue={currentSelectedTaxYear}
                placeholder='(skatteår)'
                onChange={newSelection => {
                    const newTaxYears = [...taxYears];
                    const propsToUpdate = { taxYears: newTaxYears };

                    if (newSelection === 'slet') {
                        newTaxYears.splice(newTaxYears.indexOf(currentSelectedTaxYear), 1);
                        if (newTaxYears.length <= 1) {  
                            propsToUpdate.operator = '';
                        }
                    } else {
                        newTaxYears[i] = newSelection;
                        newTaxYears.sort();
                    }

                    update(propsToUpdate);
                }}
            />
        );

        if (i === 0 && !currentSelectedTaxYear) {
            break;
        }
    }

    // seperate by dots
    return components.map((component, i) => {
        if (i !== component.length - 1) {
            return <><Dot /> {component}</>;
        }

        return component;
    });
};

const FileUploadButton = ({ onChange }) => {
    const fileRef = useRef();

    return (
        <>
            <ColoredText
                link
                underlined={false}
                content='Vælg en fil'
                iconPosition='left'
                icon='upload'
                onClick={() => fileRef.current.click()}
            />
            <input
                type='file'
                ref={fileRef}
                style={{ display: 'none' }}
                onChange={e => {
                    const [file] = e.target.files;
                    onChange(file);
                    e.target.files = null;
                }}
            />
        </>
    );
};

const EmailPicker = ({ state, setState }) => {
    const amountPerPage = 10;
    const [amountToShow, setAmountToShow] = useState(amountPerPage);

    const update = (updator = {}) => {
        const cpy = { ...state };
        cpy.filterOptions = { ...cpy.filterOptions, ...updator };
        setState(cpy);
    };

    const emails = state.filterOptions.emails;

    const onFilePicked = file => {
        const reader = new FileReader();

        reader.onload = () => {
            const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
            const emails = [...new Set(reader.result.match(emailRegex) || [])]
            update({ emails });
        };

        reader.readAsText(file);
    };

    const content = (
        <div>
            <FileUploadButton onChange={onFilePicked} />
            {emails && (
                <>
                    <Divider />

                    <b>{emails.length} emails fundet</b>

                    {emails.slice(0, amountToShow).map(email => <div>{email}</div>)}

                    {emails.length > amountToShow && (
                        <ColoredText
                            link
                            underlined={false}
                            icon='arrow down'
                            iconPosition='left'
                            content='Vis flere...'
                            onClick={() => setAmountToShow(amountToShow + amountPerPage)}
                        />
                    )}
                </>
            )}
        </div>
    );
    
    return (
        <Popup
            position='top center'
            on='click'
            content={content}
            trigger={
                <ColoredText link underlined={false} color='blue'>
                    <Dot /> <Icon name='mail' /> {emails?.length ?? 0} valgt
                </ColoredText>
            }
        />
    );
};

const FilterItem = ({ state, setState, onDelete, onCopy, validationError, filterChoices }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const renderChoices = (prop, choices) => {
        return (
            <FilterItemDropdown
                options={choices}
                defaultValue={state[prop]}
                defaultOpen={!state[prop]}
                placeholder='Vælg...'
                onChange={value => {
                    const newState = { ...state };
                    if (prop === 'filterKind') {
                        newState.filterOptions = {};
                    }
                    newState[prop] = value;
                    setState(newState);
                }}
            />
        );
    };

    const renderFilterSubComponent = () => {
        const chosenFilterKind = filterChoices.find(x => x.value === state.filterKind);
        if (!chosenFilterKind) {
            return null;
        }

        const { Component } = chosenFilterKind;

        if (!Component) {
            return null;
        }

        return <Component state={state} setState={setState} />;
    };

    let tooltip;
    let icon;
    let color;

    if (validationError) {
        icon = 'info circle';
        color = 'grey';
        tooltip = (
            <div>
                <b>Filter ikke klar:</b>
                <br />
                {validationError}
            </div>
        );
    } else {
        color = 'green';
    }

    return (
        <Segment
            style={{
                border: '1px solid lightgray',
                padding: '1em',
                borderRadius: '0.5em',
                margin: 0,
            }}
            color={color}
        >
            <span>
                {renderChoices('includeOrExclude', [
                    { text: 'Inkluder modtagere', value: 'include' },
                    { text: 'Ekskluder modtagere', value: 'exclude' },
                ])}

                {state.includeOrExclude && (
                    <>
                        <Dot/>{' '}
                        {renderChoices('filterKind', filterChoices.map(x => ({ text: x.text, value: x.value })))}
                        {renderFilterSubComponent()}
                    </>
                )}
            </span>

            <div style={{ float: 'right' }}>
                {tooltip && (
                    <Popup
                        basic
                        position='top center'
                        trigger={<Icon name={icon} color={color} />}
                        content={tooltip}
                    />
                )}
                <Popup
                    basic
                    onClose={() => setMenuOpen(false)}
                    onOpen={() => setMenuOpen(true)}
                    open={menuOpen}
                    on='click'
                    position='top center'
                    trigger={<Icon name='ellipsis vertical' link />}
                    content={
                        <List style={{ cursor: 'pointer', selection: 'none' }} onClick={() => setMenuOpen(false)}>
                            <List.Item onClick={onCopy}>
                                <Icon name='copy' /> Kopier
                            </List.Item>
                            <List.Item onClick={onDelete}>
                                <Icon name='trash' color='red' /> Slet
                            </List.Item>
                        </List>
                    }
                />
            </div>
        </Segment>
    );
};

const makeFilterItem = () => ({
    id: uuid(),
    includeOrExclude: '',
    filterKind: '',
    filterOptions: {},
});

export const filterChoiceTypes = {
    boughtTaxYears: 'boughtTaxYears',
    emailAddresses: 'emailAddresses',
};

export const allFilterChoices = [
    {
        value: filterChoiceTypes.boughtTaxYears,
        text: 'der har købt',
        Component: TaxYearPicker,
    },
    {
        value: filterChoiceTypes.emailAddresses,
        text: 'med bestemte e-mailaddresser',
        Component: EmailPicker,
    },
];

const validateFilterItem = (filterItem, activeFilterChoices) => {
    if (!filterItem.includeOrExclude) {
        return 'Vælg mellem inklusion og eksklusion';
    }

    if (!filterItem.filterKind) {
        return 'Vælg type af filter';
    }

    if (!activeFilterChoices.some(choice => choice.value === filterItem.filterKind)) {
        return 'Dette filter er ikke længere validt givet valgte segment(er)';
    }

    if (filterItem.filterKind === filterChoiceTypes.boughtTaxYears) {
        const { taxYears, operator } = filterItem.filterOptions || {};
        if (operator && taxYears.length <= 1) {
            return 'Vælg mere en ét skatteår';
        }
    }

    if (filterItem.filterKind === filterChoiceTypes.emailAddresses) {
        const emails = filterItem.filterOptions?.emails || [];
        if (emails.length === 0) {
            return 'Du har ikke valgt nogen mailadresser';
        }
    }

    // filter item is valid
    return null;
};

export const validateFilterItems = (filterItems, activeFilterChoices) => {
    const errors = filterItems.flatMap((item, index) => {
        const validationError = validateFilterItem(item, activeFilterChoices);
        if (validationError) {
            return [{ message: validationError, index }];
        }
        return [];
    });

    return {
        validationErrors: errors,
        valid: errors.length === 0,
    };
};

const UserFilterPipeline = ({ filterItems, setFilterItems, activeFilterChoices }) => {
    const renderFilterItem = item => {
        return (
            <FilterItem
                key={item.id}
                state={item}
                filterChoices={activeFilterChoices}
                validationError={validateFilterItem(item, activeFilterChoices)}
                onDelete={() => setFilterItems(filterItems.filter(filterItem => filterItem.id !== item.id))}
                onCopy={() => setFilterItems(filterItems.flatMap(filterItem => {
                    if (filterItem.id === item.id) {
                        const copy = structuredClone(filterItem);
                        copy.id = uuid();
                        return [filterItem, copy];
                    }

                    return [filterItem];
                }))}
                setState={newItem => setFilterItems(
                    filterItems.map(filterItem => {
                        if (filterItem.id === item.id) {
                            return newItem;
                        }

                        return filterItem;
                    })
                )}
            />
        );
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
            {filterItems.map(renderFilterItem)}
            <div
                style={{
                    border: '1px dashed lightgray',
                    padding: '1em',
                    borderRadius: '0.5em',
                    cursor: 'pointer',
                }}
                onClick={() => setFilterItems([...filterItems, makeFilterItem()])}
            >
                <Icon name='plus circle' /> Nyt filter
            </div>
        </div>
    );
};

export default UserFilterPipeline;
